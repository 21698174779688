import config from "../../../config"
import { 
    storeDataUsers,
    storeDataUsersClone,
    storeDataTypeUserUsersClone,
    storeInfoUser,
} from '../../Reducers/Administrator/Users'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetUsersServices = async (dispatch) => {
    await fetch(config.api_url+`users/get-user`, {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            const data_type_users = data.data
            data_type_users.map((tuser, index) => {
                data_type_users[index]['key'] = index + 1
            })
            dispatch(storeDataUsers(data_type_users))
            dispatch(storeDataUsersClone(data_type_users))

            dispatch(storeInfoUser({}))

            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}

export const GetTypeUserUsersServices = async (dispatch) => {
    await fetch(config.api_url+`type-user/get-type-user`, {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            const data_tpu = data.data.map(tpu => ({ value: tpu.tpuid, label: tpu.tpunombre }))
            dispatch(storeDataTypeUserUsersClone(data_tpu))
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}

export const GetUniqueUsersServices = async (dispatch, user_id) => {
    if(user_id){
        await fetch(config.api_url+`users/get-unique-user`, {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken': localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_usuid: user_id,
            })
        })
        .then(res => res.json())
        .then(async data => {
            if(data.response){
                // notificacionServidor('success', data.message)
                const data_unique = data.data

                const data_unique_user = {
                    usuid: data_unique.usuid,
                    pernombre: data_unique.perpersonas ? data_unique.perpersonas.pernombre : '',
                    perapellidopaterno: data_unique.perpersonas ? data_unique.perpersonas.perapellidopaterno : '',
                    perapellidomaterno: data_unique.perpersonas ? data_unique.perpersonas.perapellidomaterno : '',
                    usuusuario: data_unique.usuusuario,
                    tpuid: data_unique.tpuid,
                    usuestado: data_unique.usuestado,
                }

                dispatch(storeInfoUser(data_unique_user))
            }else{
                notificacionServidor('warning', data.message)
                throw new Error(`${data.message}`)
            }
        })
        .catch(error => {
            console.log(error)
            throw new Error('Los sentimos hubo un error en el servidor.')
        })
    }

    return true
}

export const CreateUsersServices = async (data_type_user) => {
    let response = true
    if(data_type_user.pernombre && data_type_user.perapellidopaterno && data_type_user.usuusuario && data_type_user.tpuid && data_type_user.password){
        await fetch(config.api_url+`users/create-user`, {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken': localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_name: data_type_user.pernombre,
                req_paternal_surname: data_type_user.perapellidopaterno,
                req_maternal_surname: data_type_user.perapellidomaterno ? data_type_user.perapellidomaterno : '',
                req_usuario: data_type_user.usuusuario,
                req_password: data_type_user.password,
                req_estado: data_type_user.usuestado ? true : false,
                req_tpuid: data_type_user.tpuid,
            })
        })
        .then(res => res.json())
        .then(async data => {
            if(data.response){
                notificacionServidor('success', data.message)
            }else{
                response = false
                notificacionServidor('warning', data.message)
                throw new Error(`${data.message}`)
            }
        })
        .catch(error => {
            console.log(error)
            throw new Error('Los sentimos hubo un error en el servidor.')
        })
    }else{
        response = false
        notificacionServidor('warning', "Los sentimos los campos Nombres, Apellido Paterno, Usuario, Contraseña y Tipo de Usuario son necesarios")
        throw new Error('Los sentimos los campos Nombres, Apellido Paterno, Usuario, Contraseña y Tipo de Usuario son necesarios')
    }

    return response
}

export const UpdateUsersServices = async (data_type_user) => {
    let response = true
    if(data_type_user.pernombre && data_type_user.perapellidopaterno && data_type_user.usuusuario && data_type_user.tpuid){
        await fetch(config.api_url+`users/update-user`, {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken': localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_usuid: data_type_user.usuid,
                req_name: data_type_user.pernombre,
                req_paternal_surname: data_type_user.perapellidopaterno,
                req_maternal_surname: data_type_user.perapellidomaterno ? data_type_user.perapellidomaterno : '',
                req_usuario: data_type_user.usuusuario,
                req_password: data_type_user.password,
                req_estado: data_type_user.usuestado ? true : false,
                req_tpuid: data_type_user.tpuid,
            })
        })
        .then(res => res.json())
        .then(async data => {
            if(data.response){
                notificacionServidor('success', data.message)
            }else{
                response = false
                notificacionServidor('warning', data.message)
                throw new Error(`${data.message}`)
            }
        })
        .catch(error => {
            console.log(error)
            throw new Error('Los sentimos hubo un error en el servidor.')
        })
    }else{
        response = false
        notificacionServidor('warning', "Los sentimos los campos Nombres, Apellido Paterno, Usuario y Tipo de Usuario son necesarios")
        throw new Error('Los sentimos los campos Nombres, Apellido Paterno, Usuario y Tipo de Usuario son necesarios')
    }

    return response
}

export const DeleteUsersServices = async (user_id) => {
    let response = true
    
    await fetch(config.api_url+`users/delete-user`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_usuid: user_id,
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            response = false
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return response
}
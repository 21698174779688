import { configureStore } from "@reduxjs/toolkit"
import Baseline from "../Reducers/Baseline"
import FileUpload from "../Reducers/Fileupload"
import User from "../Reducers/User"
import Users from "../Reducers/Administrator/Users"
import Dashboard from "../Reducers/Administrator/Dashboard"
import Audits from "../Reducers/Administrator/Audits"
import DownloadFiles from "../Reducers/Administrator/DownloadFiles"
import TypeUsers from "../Reducers/Administrator/TypeUsers"
import BaselineForecast from "../Reducers/BaselineForecast"
import Utilities from "../Reducers/Utilities"

export const store = configureStore({
    reducer: {
        baseline : Baseline,
        fileUpload: FileUpload,
        user: User,
        users: Users,
        dashboard: Dashboard,
        audits: Audits,
        downloadFiles: DownloadFiles,
        typeUsers: TypeUsers,
        baselineForecast: BaselineForecast,
        utilities: Utilities,
    }
})
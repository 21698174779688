import { useQuery, useMutation } from "@tanstack/react-query"
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { storeDataPermissionsTypeUsersClone } from "../../../Redux/Reducers/Utilities"
import { CreateTypeUsersServices, GetTypeUsersServices, GetUniqueTypeUsersServices, UpdateTypeUsersServices } from "../../../Redux/Services/Administrator/TypeUsers"
import { ChangeTypeUsersActions } from "../../../Redux/Actions/Administrador/TypeUsers"
import { Button, Col, Row, Form, Input, Typography, Switch, Divider, Checkbox } from 'antd'
import {
    LoadingOutlined,
    SaveOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons'
import '../../../Styles/Routes/Administrator/TypeUsers/CreateUpdateTypeUsers.css'
import { storeTypeUser } from "../../../Redux/Reducers/Administrator/TypeUsers"

const CreateUpdateTypeUsers = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const { Title } = Typography

    const createTypeUser = useMutation({
        mutationFn: () => CreateTypeUsersServices(rex_type_user, rex_data_permissions_type_users_clone),
        onSuccess: () => {
            navigate('/administrador/tipos-usuarios')
        }
    })

    const updateTypeUser = useMutation({
        mutationFn: () => UpdateTypeUsersServices(rex_type_user, rex_data_permissions_type_users_clone),
        onSuccess: () => {
            navigate('/administrador/tipos-usuarios')
        }
    })

    const { 
        rex_type_user
    } = useSelector(state => state.typeUsers)

    const { 
        rex_data_permissions_type_users_clone,
        rex_validate_permissions,
    } = useSelector(state => state.utilities)

    const unique_type_users = useQuery({
        queryKey: ['uniquetypeuser'],
        queryFn : () => GetUniqueTypeUsersServices(dispatch, id, rex_data_permissions_type_users_clone),
        retry: 1,
        refetchOnWindowFocus : false,
        enabled: !!rex_validate_permissions
    })

    const changeTypeUser = async (value, tpeid, pemid) => {
        const data_type_user = await ChangeTypeUsersActions(rex_data_permissions_type_users_clone, value, tpeid, pemid)
        dispatch(storeDataPermissionsTypeUsersClone(data_type_user))
    }

    const changeNameTypeUser = (value, type) => {
        let data_store_type_user = rex_type_user
        if(type == "tpunombre"){
            data_store_type_user = {
                ...data_store_type_user,
                tpunombre: value,
            }
        }else if(type == "tpuestado"){
            data_store_type_user = {
                ...data_store_type_user,
                tpuestado: value,
            }
        }
        
        dispatch(storeTypeUser(data_store_type_user))
    }

    const createUpdateTypeUser = async () => {
        if(id){
            updateTypeUser.mutate()
        }else{
            createTypeUser.mutate()
        }
    }

	return (
		<Row>
            <Col span={24}>
                <Title level={4}>{id ? "Editar" : "Crear"} tipo de Usuario</Title>
            </Col>
            <Col span={24}>
                <Form
                    layout='vertical'
                    onFinish={() => createUpdateTypeUser()}
                >
                    <Row gutter={[20, 0]}>
                        <Col span={12}>
                            <Form.Item
                                rules={[
                                    { required: true, message: 'El nombre es necesario' },
                                ]}
                                label='Nombre:'
                            >
                                <Input
                                    value={rex_type_user?.tpunombre}
                                    autoComplete='off'
                                    onChange={(e) => changeNameTypeUser(e.target.value, "tpunombre")}
                                    size="small"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Estado:'
                            >
                                <Switch
                                    onChange={(value) => changeNameTypeUser(value, "tpuestado")}
                                    size='small'
                                    checked={rex_type_user?.tpuestado}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider 
                        style={{margin:'0 0 5px 0'}} 
                        orientation="left" 
                        plain
                    >
                        Permisos:
                    </Divider>
                    <Row>
                        <Col span={12}>
                            {
                                rex_data_permissions_type_users_clone.length > 0
                                ?   rex_data_permissions_type_users_clone.map((perms, tpu_index) => {
                                    if(tpu_index % 2 == 0){
                                        return <div style={{border:'1px solid #EEEDED', padding:'10px 15px', borderRadius:'5px', margin:'0 0 10px 0', backgroundColor: '#fff'}}>
                                            <div className="Container-Type-Permissions">
                                                <b>{perms.tpenombre}</b><span></span>
                                                <Checkbox
                                                    checked={perms.checked}
                                                    onChange={(e) => changeTypeUser(e.target.checked, perms.tpeid)}
                                                />
                                            </div>
                                            {
                                                perms.pempermisos.map(pems => 
                                                    <div className="Container-Permissions">
                                                        <span>{pems.pemnombre}</span>
                                                        <Checkbox
                                                            checked={pems.checked}
                                                            onChange={(e) => changeTypeUser(e.target.checked, perms.tpeid, pems.pemid)}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }
                                })
                                :   null
                            }
                        </Col>
                        <Col span={12}>
                            {
                                rex_data_permissions_type_users_clone.length > 0
                                ?   rex_data_permissions_type_users_clone.map((perms, tpu_index) => {
                                    if(tpu_index % 2 != 0){
                                        return <div style={{border:'1px solid #EEEDED', padding:'10px 15px', borderRadius:'5px', margin:'0 0 10px 0', backgroundColor: '#fff'}}>
                                            <div className="Container-Type-Permissions">
                                                <b>{perms.tpenombre}</b><span></span>
                                                <Checkbox
                                                    checked={perms.checked}
                                                    onChange={(e) => changeTypeUser(e.target.checked, perms.tpeid)}
                                                />
                                            </div>
                                            {
                                                perms.pempermisos.map(pems => 
                                                    <div className="Container-Permissions">
                                                        <span>{pems.pemnombre}</span>
                                                        <Checkbox
                                                            checked={pems.checked}
                                                            onChange={(e) => changeTypeUser(e.target.checked, perms.tpeid, pems.pemid)}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }
                                })
                                :   null
                            }
                        </Col>
                        <Col span={24} style={{marginTop: '10px'}}>
                            <Form.Item className="Container-Button-Type-Users">
                                <Button 
                                    danger
                                    size="small"
                                    onClick={() => navigate('/administrador/tipos-usuarios')}
                                >
                                    Cancelar
                                </Button>
                                <Button 
                                    type="primary" 
                                    ghost
                                    size="small"
                                    htmlType="submit"
                                >
                                    {
                                        id   
                                        ?   updateTypeUser.isPending
                                            ?   <><LoadingOutlined style={{marginRight:'6px'}} /> Actualizar</>
                                            :   <><SaveOutlined style={{marginRight:'6px'}}/> Actualizar</>
                                        :   createTypeUser.isPending
                                            ?   <><LoadingOutlined style={{marginRight:'6px'}} /> Guardar</>
                                            :   <><SaveOutlined style={{marginRight:'6px'}}/> Guardar</>
                                    }
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
		</Row>
	)
}

export default CreateUpdateTypeUsers
import { useQuery, useMutation } from "@tanstack/react-query"
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { CreateUsersServices, GetTypeUserUsersServices, GetUniqueUsersServices, UpdateUsersServices } from "../../../Redux/Services/Administrator/Users"
import { Button, Col, Row, Form, Input, Typography, Switch, Select } from 'antd'
import {
    LoadingOutlined,
    SaveOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons'
import '../../../Styles/Routes/Administrator/TypeUsers/CreateUpdateTypeUsers.css'
import { storeInfoUser } from "../../../Redux/Reducers/Administrator/Users"

const CreateUpdateUsers = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const { Title } = Typography

    const { data: response_vers } = useQuery({
        queryKey: ['typeUserUsers'],
        queryFn : () => GetTypeUserUsersServices(dispatch),
        retry: 1,
        refetchOnWindowFocus : false,
    })

    const createUser = useMutation({
        mutationFn: () => CreateUsersServices(rex_info_user),
        onSuccess: () => {
            navigate('/administrador/usuarios')
        },
    })

    const updateUser = useMutation({
        mutationFn: () => UpdateUsersServices(rex_info_user),
        onSuccess: () => {
            navigate('/administrador/usuarios')
        }
    })

    const { 
        rex_info_user, 
        rex_data_type_user_users,
    } = useSelector(state => state.users)

    const uniqueUser = useQuery({
        queryKey: ['uniqueUser'],
        queryFn : () => GetUniqueUsersServices(dispatch, id),
        retry: 1,
        refetchOnWindowFocus : false,
        enabled: !!response_vers
    })

    const changeNameTypeUser = (value, type) => {
        let data_info_user = rex_info_user
        if(type == "pernombre"){
            data_info_user = {
                ...data_info_user,
                pernombre: value,
            }
        }else if(type == "perapellidopaterno"){
            data_info_user = {
                ...data_info_user,
                perapellidopaterno: value,
            }
        }else if(type == "perapellidomaterno"){
            data_info_user = {
                ...data_info_user,
                perapellidomaterno: value,
            }
        }else if(type == "usuusuario"){
            data_info_user = {
                ...data_info_user,
                usuusuario: value,
            }
        }else if(type == "password"){
            data_info_user = {
                ...data_info_user,
                password: value,
            }
        }else if(type == "tpuid"){
            data_info_user = {
                ...data_info_user,
                tpuid: value,
            }
        }else if(type == "usuestado"){
            data_info_user = {
                ...data_info_user,
                usuestado: value,
            }
        }
        
        dispatch(storeInfoUser(data_info_user))
    }

    const createUpdateUser = async () => {
        if(id){
            updateUser.mutate()
        }else{
            createUser.mutate()
        }
    }

	return (
		<Row>
            <Col span={24}>
                <Title level={4}>{id ? "Editar" : "Crear"} Usuario</Title>
            </Col>
            <Col span={24}>
                <Form
                    layout='vertical'
                    onFinish={() => createUpdateUser()}
                >
                    <Row gutter={[20, 0]}>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { required: true, message: 'El nombre es necesario' },
                                ]}
                                label='Nombres:'
                            >
                                <Input
                                    value={rex_info_user?.pernombre}
                                    autoComplete='off'
                                    onChange={(e) => changeNameTypeUser(e.target.value, "pernombre")}
                                    size="small"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { required: true, message: 'El apellido paterno es necesario' },
                                ]}
                                label='Apellido Paterno:'
                            >
                                <Input
                                    value={rex_info_user?.perapellidopaterno}
                                    autoComplete='off'
                                    onChange={(e) => changeNameTypeUser(e.target.value, "perapellidopaterno")}
                                    size="small"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Apellido Materno:'
                            >
                                <Input
                                    value={rex_info_user?.perapellidomaterno}
                                    autoComplete='off'
                                    onChange={(e) => changeNameTypeUser(e.target.value, "perapellidomaterno")}
                                    size="small"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { required: true, message: 'El usuario es necesario' },
                                ]}
                                label='Usuario:'
                            >
                                <Input
                                    value={rex_info_user?.usuusuario}
                                    autoComplete='off'
                                    onChange={(e) => changeNameTypeUser(e.target.value, "usuusuario")}
                                    size="small"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Contraseña:'
                            >
                                <Input.Password
                                    autoComplete='off'
                                    onChange={(e) => changeNameTypeUser(e.target.value, "password")}
                                    size="small"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Tipo de usuario:'
                                rules={[
                                    { required: true, message: 'El tipo de usuario es requerido' },
                                ]}
                            >
                                <Select
                                    placeholder='Seleccionar tipo de usuario'
                                    size="small"
                                    onChange={(value) => changeNameTypeUser(value, "tpuid")}
                                    options={rex_data_type_user_users}
                                    value={rex_info_user?.tpuid}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Estado:'
                            >
                                <Switch
                                    onChange={(value) => changeNameTypeUser(value, "usuestado")}
                                    size='small'
                                    checked={rex_info_user?.usuestado}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{marginTop: '10px'}}>
                            <Form.Item className="Container-Button-Type-Users">
                                <Button 
                                    danger
                                    size="small"
                                    onClick={() => navigate('/administrador/usuarios')}
                                >
                                    Cancelar
                                </Button>
                                <Button 
                                    type="primary" 
                                    ghost
                                    size="small"
                                    htmlType="submit"
                                >
                                    {
                                        id   
                                        ?   updateUser.isPending
                                            ?   <><LoadingOutlined style={{marginRight:'6px'}} /> Actualizar</>
                                            :   <><SaveOutlined style={{marginRight:'6px'}}/> Actualizar</>
                                        :   createUser.isPending
                                            ?   <><LoadingOutlined style={{marginRight:'6px'}} /> Guardar</>
                                            :   <><SaveOutlined style={{marginRight:'6px'}}/> Guardar</>
                                    }
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
		</Row>
	)
}

export default CreateUpdateUsers
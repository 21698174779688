import config from "../../config"
import { notification } from 'antd'
import { storeDataBaselineForecast } from "../Reducers/BaselineForecast"
import { refreshDashboardServices } from "./Baseline"

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataBaselineForecastServices = async (dispatch) => {
    await fetch(config.api_url+`decks/get-baseline-forecast`, {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            const data_baseline_forecast = data.data
            data_baseline_forecast.map((tuser, index) => {
                data_baseline_forecast[index]['key'] = index + 1
            })

            dispatch(storeDataBaselineForecast(data_baseline_forecast))
            // notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}

export const ChangeStatusBaselineForecastServices = async (data, dispatch) => {
    let response = true
    await fetch(config.api_url+`decks/change-baseline-forecast`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_id_deck: data.mdeckid,
            req_state: data.mdeckestado,
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            notificacionServidor('success', data.message)
            dispatch(refreshDashboardServices(dispatch))
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
            response = false
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
        response = false
    })

    return response
}
import { useState } from "react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DeleteTypeUsersServices, GetTypeUsersServices } from "../../../Redux/Services/Administrator/TypeUsers"
import { Row, Col, Popconfirm, Table, Input, Button } from 'antd'
import {
    FilterOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import '../../../Styles/Routes/Administrator/TypeUsers/TypeUsers.css'

const TypeUsers = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 15
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const type_users = useQuery({
        queryKey: ['typeusers'],
        queryFn : () => GetTypeUsersServices(dispatch),
        retry: 1,
        refetchOnWindowFocus : false,
    })

    const deleteTypeUser = useMutation({
        mutationFn: (type_user_id) => DeleteTypeUsersServices(type_user_id),
        onSuccess: () => {
            queryClient.invalidateQueries(['typeusers'])
        }
    })

    const { 
        rex_data_type_users, 
        rex_data_type_users_clone, 
    } = useSelector(state => state.typeUsers)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined style={{color:'white'}}/>),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
    })

    const columns = [
        {
            title: 'N°',
            dataIndex: 'key',
            showSorterTooltip: false,
            render: (_, __, index) => <div title={index + 1 + (currentPage - 1) * pageSize}>{index + 1 + (currentPage - 1) * pageSize}</div>,
            width: '20px',
            align:'center'
        },
        {
            title: 'Estado',
            dataIndex: 'tpuestado',
            sorter: (a, b) => a.tpuestado ? a.tpuestado - b.tpuestado : false,
            showSorterTooltip: false,
            render: (tpuestado) => <div style={{display:'flex', justifyContent:'center'}}>
                <div title={tpuestado == 1 ? 'Activado' : 'Desactivado'} className={tpuestado == 1 ? 'Status-Color-Green' : 'Status-Color-Red'}></div>
            </div>,
            width: '50px',
            align:'center'
        },
        {
            title: 'Tipo de usuario',
            dataIndex: 'tpunombre',
            ...getColumnSearchProps('tpunombre', 'Tipo de usuario'),
            sorter: (a, b) => a.tpunombre? a.tpunombre.localeCompare(b.tpunombre) : false,
            showSorterTooltip: false,
            render: (tpunombre) => <div title={tpunombre}>{tpunombre}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Acciones',
            dataIndex: '',
            render: (_, record) => <div>
                <EditOutlined 
                    title='Editar'
                    onClick={() => {
                        navigate(`/administrador/editar-tipos-usuarios/${record.tpuid}`)
                    }}
                    style={{fontSize:'15px', color:'blue', cursor:'pointer', margin:'0 2px'}}
                />
                <Popconfirm
                    title='Eliminar tipo de usuario'
                    description='Está seguro de eliminar al tipo de usuario?'
                    okText='Confirmar'
                    cancelText='Cancelar'
                    placement="bottomRight"
                    onConfirm={() => {
                        deleteTypeUser.mutate(record.tpuid)
                    }}
                >
                    <DeleteOutlined 
                        style={{fontSize:'15px', color:'red', cursor:'pointer', margin:'0 2px'}}
                    />
                </Popconfirm>
            </div>,
            width: '50px',
            align:'center'
        }
    ]

	return (
		<Row>
			<Col span={24} style={{ margin: '25px 0' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Tipos de Usuarios</div>
			</Col>
            <Col span={24}>
                <Button
                    size="small"
                    type="primary"
                    className="Button-New"
                    onClick={() => navigate('/administrador/crear-tipos-usuarios')}
                >
                    Nuevo Tipo de Usuario <PlusOutlined />
                </Button>
            </Col>
            <Col span={24}>
                <Table 
                    pagination={{
                        position: ["topRight", "none"],
                        pageSize: pageSize,
                        size: 'small',
                        onChange: (page) => setCurrentPage(page),
                        showTotal: (total) => `Total: ${total} registros`
                    }}
                    scroll={{
                        x: '1000px',
                        y: '370px',
                    }}
                    loading={type_users.isPending}
                    columns={columns}
                    dataSource={rex_data_type_users_clone} 
                    className="Table-Audits"
                    size='small'
                />
            </Col>
		</Row>
	)
}

export default TypeUsers
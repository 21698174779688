import { createSlice } from "@reduxjs/toolkit"

export const DownloadFiles = createSlice({
    name : 'downloadFiles',
    initialState : {
        rex_data_download_files: [],
        rex_data_download_files_clone: [],
    },
    reducers : {
        storeDataDownloadFiles: (state, action) => {
            state.rex_data_download_files = action.payload
        },
        storeDataDownloadFilesClone: (state, action) => {
            state.rex_data_download_files_clone = action.payload
        },
    }
})

export default DownloadFiles.reducer
export const { 
    storeDataDownloadFiles, 
    storeDataDownloadFilesClone, 
} = DownloadFiles.actions
import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useDispatch, useSelector } from 'react-redux'
import { GetDownloadFilesServices } from "../../../Redux/Services/Administrator/DownloadFiles"
import { Col, Row, DatePicker, Table, Input } from 'antd'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {
    FilterOutlined,
    CalendarOutlined,
} from '@ant-design/icons'

const DownloadFiles = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 15
    const dispatch = useDispatch()
    const downloadfiles = useQuery({
        queryKey: ['downloadfiles'],
        queryFn : () => GetDownloadFilesServices(dispatch),
        retry: 1,
        refetchOnWindowFocus : false,
    })

    const { 
        rex_data_download_files, 
        rex_data_download_files_clone, 
    } = useSelector(state => state.downloadFiles)

    const titleColumn = (columnName, column) => (
        <div className='Container-Title-Column-Approvals'>
            <span title={column}>{column}</span>
        </div>
    )

    const getColumnSearchProps = (dataIndex, texto) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{padding: 8,}}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Buscar ${texto}`}
                    onChange={async (e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{display: 'block'}}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined style={{color: '#fff'}} />),
        onFilter: (value, record) => {
            let valueData
            if(dataIndex.length == 3){
                if(record[dataIndex[0]]){
                    if(record[dataIndex[0]][dataIndex[1]]){
                        valueData = record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
                    }
                }
            }else if(dataIndex.length == 2){
                if(record[dataIndex[0]]){
                    valueData = record[dataIndex[0]][dataIndex[1]]
                }
            }else if(dataIndex.length == 1){
                valueData = record[dataIndex[0]]
            }

            return valueData ? valueData.toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const getColumnDateProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <DatePicker
                    allowClear={true}
                    onChange={(value)=> {
                        const date_value = value ? format(new Date(value.$d), 'dd/MM/yyyy') : null
                        setSelectedKeys(date_value ? [date_value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                />
            </div>
        ),
        filterIcon: () => (<CalendarOutlined style={{color: '#fff'}} />),
        onFilter: (value, record) => {
            const valueData = format(parseISO(record[dataIndex[0]]), 'dd/MM/yyyy')

            return valueData ? valueData.toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const columns = [
        {
            title: 'N°',
            dataIndex: 'key',
            showSorterTooltip: false,
            render: (_, __, index) => <div title={index + 1 + (currentPage - 1) * pageSize}>{index + 1 + (currentPage - 1) * pageSize}</div>,
            width: '50px',
            align:'center'
        },
        {
            title: titleColumn('usuusuario', 'CORREO'),
            dataIndex: 'usuusuarios.usuusuario',
            sorter: (a, b) => {
                const columnA = a?.usuusuarios?.usuusuario || ''
                const columnB = b?.usuusuarios?.usuusuario || ''
                let responseSorter = false
                if(columnA && columnB){
                    responseSorter = columnA.localeCompare(columnB)
                }

                return responseSorter
            },
            ...getColumnSearchProps(['usuusuarios', 'usuusuario'], 'Correo'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.usuusuarios.usuusuario}>{record.usuusuarios.usuusuario}</div>,
            width: '120px'
        },
        {
            title: titleColumn('darnombre', 'NOMBRE'),
            dataIndex: 'darnombre',
            sorter: (a, b) => {
                const columnA = a?.darnombre || ''
                const columnB = b?.darnombre || ''
                let responseSorter = false
                if(columnA && columnB){
                    responseSorter = columnA.localeCompare(columnB)
                }

                return responseSorter
            },
            ...getColumnSearchProps(['darnombre'], 'Nombre'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.darnombre}>
                <a href={record.darurl}>{record.darnombre}</a>
            </div>,
            width: '200px'
        },
        {
            title: titleColumn('dartipo', 'TIPO ARCHIVO'),
            dataIndex: 'dartipo',
            sorter: (a, b) => {
                const columnA = a?.dartipo || ''
                const columnB = b?.dartipo || ''
                let responseSorter = false
                if(columnA && columnB){
                    responseSorter = columnA.localeCompare(columnB)
                }

                return responseSorter
            },
            ...getColumnSearchProps(['dartipo'], 'Tipo archivo'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.dartipo}>{record.dartipo}</div>,
            width: '100px'
        },
        {
            title: titleColumn('created_at', 'FECHA Y HORA'),
            dataIndex: 'created_at',
            ...getColumnDateProps(['created_at'], 'Fecha y hora'),
            showSorterTooltip: false,
            render: (_, record) =>  
                <div title={record.created_at ? moment(record.created_at).format('DD/MM/YYYY h:mm a') : ''}>
                    {record.created_at ? moment(record.created_at).format('DD/MM/YYYY h:mm a') : ''}
                </div>,
            width: '120px'
        },
    ]

	return (
		<Row>
			<Col span={24} style={{ margin: '10px 0' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Control de Descargas Archivos</div>
			</Col>
            <Col span={24}>
                <Table 
                    pagination={{
                        position: ["topRight", "none"],
                        pageSize: pageSize,
                        size: 'small',
                        onChange: (page) => setCurrentPage(page),
                        showTotal: (total) => `Total: ${total} registros`
                    }}
                    scroll={{
                        x: '1000px',
                        y: '370px',
                    }}
                    loading={downloadfiles.isPending}
                    columns={columns}
                    dataSource={rex_data_download_files_clone} 
                    className="Table-Audits"
                    size='small'
                />
            </Col>
		</Row>
	)
}

export default DownloadFiles
import { createSlice } from "@reduxjs/toolkit"

export const TypeUsers = createSlice({
    name : 'typeUsers',
    initialState : {
        rex_data_type_users: [],
        rex_data_type_users_clone: [],
        rex_type_user: {}
    },
    reducers : {
        storeDataTypeUsers: (state, action) => {
            state.rex_data_type_users = action.payload
        },
        storeDataTypeUsersClone: (state, action) => {
            state.rex_data_type_users_clone = action.payload
        },
        storeTypeUser: (state, action) => {
            state.rex_type_user = action.payload
        },
    }
})

export default TypeUsers.reducer
export const { 
    storeDataTypeUsers, 
    storeDataTypeUsersClone,
    storeTypeUser, 
} = TypeUsers.actions
import { createSlice } from "@reduxjs/toolkit"

export const BaselineForecast = createSlice({
    name : 'baselineforecast',
    initialState : {
        rex_data_baseline_forecast: [],
    },
    reducers : {
        storeDataBaselineForecast: (state, action) => {
            state.rex_data_baseline_forecast = action.payload
        },
    }
})

export default BaselineForecast.reducer
export const { storeDataBaselineForecast } = BaselineForecast.actions
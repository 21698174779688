import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { DownloadDataServices } from "../../Redux/Services/DownloadData"
import { Button, Col, Row } from 'antd'
import '../../Styles/Routes/DownloadData.css'

const DownloadData = () => {
	const [activeDownload, setActiveDownload] = useState("Productos Pendientes")
	const [linkDownload, setLinkDonwload] = useState('products-clients-pending/products-pending')

	const downloadExcel = useMutation({
		mutationFn: () => DownloadDataServices(linkDownload),
	})

	return (
		<Row>
			<Col span={24} style={{ margin: '25px 0' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Descargas de Información</div>
			</Col>
			<Col span={21} style={{display: 'flex', gap: '10px 10px'}}>
				<Button
					onClick={() => {
						setActiveDownload('Productos Pendientes')
						setLinkDonwload('products-clients-pending/products-pending')
					}}
					className={`Button-Download-Data ${activeDownload == "Productos Pendientes" ? 'Active-Download-Data' : '' }`}
				>
					Productos Pendientes
				</Button>
				<Button
					onClick={() => {
						setActiveDownload('Clientes Pendientes')
						setLinkDonwload('products-clients-pending/clients-pending')
					}}
					className={`Button-Download-Data ${activeDownload == "Clientes Pendientes" ? 'Active-Download-Data' : '' }`}
				>
					Clientes Pendientes
				</Button>
				<Button
					onClick={() => {
						setActiveDownload('Master Productos')
						setLinkDonwload('downloads-files/master-products')
					}}
					className={`Button-Download-Data ${activeDownload == "Master Productos" ? 'Active-Download-Data' : '' }`}
				>
					Master Productos
				</Button>
				<Button
					onClick={() => {
						setActiveDownload('Master Clientes')
						setLinkDonwload('downloads-files/master-clients')
					}}
					className={`Button-Download-Data ${activeDownload == "Master Clientes" ? 'Active-Download-Data' : '' }`}
				>
					Master Clientes
				</Button>
			</Col>
			<Col span={3} style={{textAlign: 'end'}}>
				<Button
					loading={downloadExcel.isPending}
					onClick={() => downloadExcel.mutate()}
					className="Button-Excel-Download-Data"
				>
					Descargar
				</Button>
			</Col>
		</Row>
	)
}

export default DownloadData
import { createSlice } from "@reduxjs/toolkit"

export const Utilities = createSlice({
    name : 'utilities',
    initialState : {
        rex_data_permissions_type_users: [],
        rex_data_permissions_type_users_clone: [],
        rex_validate_permissions: null,
    },
    reducers : {
        storeDataPermissionsTypeUsers: (state, action) => {
            state.rex_data_permissions_type_users = action.payload
        },
        storeDataPermissionsTypeUsersClone: (state, action) => {
            state.rex_data_permissions_type_users_clone = action.payload
        },
        storeValidatePermissions: (state, action) => {
            state.rex_validate_permissions = action.payload
        },
    }
})

export default Utilities.reducer
export const { 
    storeDataPermissionsTypeUsers, 
    storeDataPermissionsTypeUsersClone, 
    storeValidatePermissions, 
} = Utilities.actions
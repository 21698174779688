import { createSlice } from "@reduxjs/toolkit"

export const Audits = createSlice({
    name : 'audits',
    initialState : {
        rex_data_audits: [],
        rex_data_audits_clone: [],
    },
    reducers : {
        storeDataAudits: (state, action) => {
            state.rex_data_audits = action.payload
        },
        storeDataAuditsClone: (state, action) => {
            state.rex_data_audits_clone = action.payload
        },
    }
})

export default Audits.reducer
export const { 
    storeDataAudits, 
    storeDataAuditsClone, 
} = Audits.actions
import { useSelector, useDispatch } from "react-redux"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Col, Row, Table } from 'antd'
import { ChangeStatusBaselineForecastServices, GetDataBaselineForecastServices } from "../../Redux/Services/BaselineForecast"
import {
    EyeOutlined,
    EyeInvisibleOutlined,
} from '@ant-design/icons'
import '../../Styles/Routes/BaselineForecast.css'

const BaselineForecast = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

	const { rex_data_baseline_forecast } = useSelector(state => state.baselineForecast)

    const baselineForecast = useQuery({
        queryKey: ['baselineForecastServices'],
        queryFn : () => GetDataBaselineForecastServices(dispatch),
        retry: 1,
        refetchOnWindowFocus : false,
    })

    const changeBaselineForecast = useMutation({
        mutationFn: (state) => ChangeStatusBaselineForecastServices(state, dispatch),
        onSuccess: () => {
            queryClient.invalidateQueries(['baselineForecastServices'])
        }
    })

    const columns = [
        {
            title: 'N°',
            dataIndex: 'key',
            sorter: (a, b) => a.key ? a.key - b.key : false,
            showSorterTooltip: false,
            render: (_, record) => <div>{record.key}</div>,
            width: '20px',
            align:'center'
        },
        {
            title: 'Nombre',
            dataIndex: 'mdeckname',
            sorter: (a, b) => a.mdeckname ? a.mdeckname.localeCompare(b.mdeckname) : false,
            showSorterTooltip: false,
            render: (_, record) => <div title={record?.mdeckname}>{record?.mdeckname}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Tipo',
            dataIndex: 'mdeckforecast',
            showSorterTooltip: false,
            render: (_, record) => <div title={record.mdeckforecast == 1 ? 'Forecast' : 'Baseline'}>{record.mdeckforecast == 1 ? 'Forecast' : 'Baseline'}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Visualización',
            dataIndex: 'mdeckestado',
            showSorterTooltip: false,
            render: (_, record) => <div title="">
                {
                    record.mdeckestado
                    ?   <EyeOutlined 
                            className="Icon-Visible-Baseline-Forecast"
                            onClick={() => {
                                const info = { mdeckid: record.mdeckid, mdeckestado: false }
                                changeBaselineForecast.mutate(info)
                            }} 
                        />
                    :   <EyeInvisibleOutlined 
                            className="Icon-Not-Visible-Baseline-Forecast"
                            onClick={() => {
                                const info = { mdeckid: record.mdeckid, mdeckestado: true }
                                changeBaselineForecast.mutate(info)
                            }}
                        />
                }
            </div>,
            width: '100px',
            align:'center'
        }
    ]

    return (
        <Row>
            <Col span={24} style={{ margin: '25px 0' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Listado Baseline | Forecast</div>
			</Col>
            <Col span={24}>
                <Table 
                    pagination={{
                        position: ["topRight", "none"],
                        pageSize: 10,
                    }}
                    scroll={{
                        x: '1000px',
                        y: '370px',
                    }}
                    loading={baselineForecast.isPending}
                    columns={columns}
                    dataSource={rex_data_baseline_forecast} 
                    className="Table-Audits"
                    size='small'
                />
            </Col>
        </Row>
    )
}

export default BaselineForecast
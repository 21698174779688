import { createSlice } from "@reduxjs/toolkit"

export const Users = createSlice({
    name : 'users',
    initialState : {
        rex_data_users: [],
        rex_data_users_clone: [],
        rex_data_type_user_users: [],
        rex_info_user: {}
    },
    reducers : {
        storeDataUsers: (state, action) => {
            state.rex_data_users = action.payload
        },
        storeDataUsersClone: (state, action) => {
            state.rex_data_users_clone = action.payload
        },
        storeDataTypeUserUsersClone: (state, action) => {
            state.rex_data_type_user_users = action.payload
        },
        storeInfoUser: (state, action) => {
            state.rex_info_user = action.payload
        },
    }
})

export default Users.reducer
export const { 
    storeDataUsers, 
    storeDataUsersClone,
    storeDataTypeUserUsersClone,
    storeInfoUser, 
} = Users.actions
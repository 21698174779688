import config from "../../../config"
import { storeDataTypeUsers, storeDataTypeUsersClone, storeTypeUser } from '../../Reducers/Administrator/TypeUsers'
import { storeDataPermissionsTypeUsersClone } from "../../Reducers/Utilities"
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetTypeUsersServices = async (dispatch) => {
    await fetch(config.api_url+`type-user/get-type-user`, {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            const data_type_users = data.data
            data_type_users.map((tuser, index) => {
                data_type_users[index]['key'] = index + 1
            })
            dispatch(storeDataTypeUsers(data_type_users))
            dispatch(storeDataTypeUsersClone(data_type_users))

            dispatch(storeTypeUser({}))

            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}

export const GetUniqueTypeUsersServices = async (dispatch, type_user_id, permissions) => {
    if(type_user_id){
        await fetch(config.api_url+`type-user/get-unique-type-user`, {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken': localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_id: type_user_id,
            })
        })
        .then(res => res.json())
        .then(async data => {
            if(data.response){
                const perms_tup_unique = data.data
                const data_permissions = permissions.map(tupperm => {
                    const pempermisos = tupperm.pempermisos.map(pems => {
                        const find_pem = perms_tup_unique.tuptiposusuariospermisos.find(tup => tup.pemid == pems.pemid)
                        if(find_pem){
                            return { ...pems, checked: true }
                        }

                        return pems
                    })

                    if(tupperm.pempermisos.length == pempermisos.filter(pems => pems.checked).length){
                        return { ...tupperm, pempermisos: pempermisos, checked: true }
                    }

                    return { ...tupperm, pempermisos: pempermisos }
                })
                dispatch(storeDataPermissionsTypeUsersClone(data_permissions))

                const store_type_user = { tpuid: perms_tup_unique.tpuid, tpunombre: perms_tup_unique.tpunombre, tpuestado: perms_tup_unique.tpuestado }
                dispatch(storeTypeUser(store_type_user))
    
                // notificacionServidor('success', data.message)
            }else{
                notificacionServidor('warning', data.message)
                throw new Error(`${data.message}`)
            }
        })
        .catch(error => {
            console.log(error)
            throw new Error('Los sentimos hubo un error en el servidor.')
        })
    }

    return true
}

export const CreateTypeUsersServices = async (data_type_user, perms) => {
    let response = true
    const perms_acc = []
    perms.map(tpeperm => {
        tpeperm.pempermisos.map(perm => {
            if(perm.checked){
                perms_acc.push({pemid: perm.pemid})
            }
        })
    })

    await fetch(config.api_url+`type-user/create-type-user`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_name: data_type_user.tpunombre,
            req_state: data_type_user.tpuestado,
            req_pems: perms_acc,
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            response = false
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return response
}

export const UpdateTypeUsersServices = async (data_type_user, perms) => {
    let response = true
    const perms_acc = []
    perms.map(tpeperm => {
        tpeperm.pempermisos.map(perm => {
            if(perm.checked){
                perms_acc.push({pemid: perm.pemid})
            }
        })
    })

    await fetch(config.api_url+`type-user/update-type-user`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_id: data_type_user.tpuid,
            req_name: data_type_user.tpunombre,
            req_state: data_type_user.tpuestado,
            req_pems: perms_acc,
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            response = false
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return response
}

export const DeleteTypeUsersServices = async (type_user_id) => {
    let response = true
    
    await fetch(config.api_url+`type-user/delete-type-user`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_id: type_user_id,
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            response = false
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return response
}
import config from "../../config"

export const DownloadDataServices = async (url_download) => {
    await fetch(config.api_url+url_download, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({})
    })
    .then(res => res.json())
    .then(data => {
        if(data.response){
            if(data.data){
                const downloadLink = data.data
                const link = document.createElement('a')
                link.href = downloadLink.url
                link.download = downloadLink.darnombre
                link.click()
            }
        }else{
            // if(data.statusCode == 400){
            //     notificacionServidor('warning', data.message)
            // }else{
            //     notificacionServidor('error', data.message)
            // }
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })
}
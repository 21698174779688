export const ChangeTypeUsersActions = async (data_permissions, value, tpeid, pemid) => {
    let data_perms = data_permissions
    if(pemid){
        data_perms = data_perms.map((dtu) => {
            if(dtu.tpeid == tpeid){
                const pempermisos = dtu.pempermisos.map(pems => {
                    if(pems.pemid == pemid){
                        return { ...pems, checked: value }
                    }

                    return { ...pems }
                })

                if(dtu.pempermisos.length == pempermisos.filter(pems => pems.checked).length){
                    return { ...dtu, pempermisos: pempermisos, checked: true }
                }

                return { ...dtu, pempermisos: pempermisos, checked: false }
            }

            return { ...dtu }
        })
    }else{
        data_perms = data_perms.map(dtu => {
            if(dtu.tpeid == tpeid){
                let pempermisos = dtu.pempermisos
                if(dtu.pempermisos.length > 0){
                    pempermisos = dtu.pempermisos.map(per => ({ ...per, checked: value }))
                }

                return { ...dtu, pempermisos: pempermisos, checked: value }
            }

            return { ...dtu }
        })
    }

    return data_perms
}
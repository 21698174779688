import config from "../../../config"
import { storeDataAudits, storeDataAuditsClone } from '../../Reducers/Administrator/Audits'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetAuditsServices = async (dispatch) => {
    await fetch(config.api_url+`audits/get-audits`, {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': localStorage.getItem('usutoken'),
        },
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            const data_audits = data.data
            data_audits.map((aud, index) => {
                data_audits[index]['key'] = index + 1
            })
            dispatch(storeDataAudits(data_audits))
            dispatch(storeDataAuditsClone(data_audits))

            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}
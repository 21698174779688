import './App.css'
import RoutesApp from './Routes/RoutesApp'
import Login from './Routes/Login/Login'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function App() {
	const { rex_data_user } = useSelector(state => state.user)

	const validateToken = () => {
        return !localStorage.getItem('usutoken') ? false : true
    }

	return (
		<>
			<RoutesApp/>
			{/* {
				validateToken()
					? 	<RoutesApp/>
					: 	<Routes>
							<Route path="*" element={<Navigate replace to="/login" />} />
							<Route exact path='/login' element={<Login/>}/>
						</Routes>
			} */}
		</>
	)
}

export default App
